import React from "react";
import { Row, Col } from "reactstrap";

import CountriesMap from "./CountriesMap";
import Reminders from "./Reminders";
import Requests from "./Requests";
import Leads from "./Leads";
import Clients from "./Clients";
import KYC from "./KYC";
import Transactions from "./Transactions";

const OperationsTab = () => (
  <>
    <Row className="col-card-same-height">
      <Col sm={6} xs={12} className="col mb-4">
        <CountriesMap />
      </Col>
      <Col sm={3} xs={12} className="col mb-4">
        <Reminders type={1} />
      </Col>
      <Col sm={3} xs={12} className="col mb-4">
        <Reminders type={0} />
      </Col>
    </Row>
    <Row className="col-card-same-height">
      <Col sm={6} xs={12} className="col mb-4">
        <Requests />
      </Col>
      <Col sm={3} className="col mb-4">
        <Leads />
      </Col>
      <Col sm={3} className="col mb-4">
        <Clients />
      </Col>
    </Row>
    <Row className="col-card-same-height">
      <Col sm={6} xs={12} className="col mb-4">
        <Transactions />
      </Col>
      <Col sm={6} xs={12} className="col mb-4">
        <KYC />
      </Col>
    </Row>
  </>
);

export default OperationsTab;