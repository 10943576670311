import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { withTranslation } from "react-i18next";

function ReceiptModal(props){
  const { imageUrl, open, onClose } = props;
  const baseUrl = process.env.REACT_APP_API_CRM_DOMAIN;
  return (
    <React.Fragment>
      <div>
        <Modal isOpen= {open} toggle = {onClose} centered = {true} size = {"lg"}>
          <ModalHeader toggle = {onClose} tag="h4">
            {props.t("Bank Receipt")}
          </ModalHeader>
          <ModalBody>
            <img
              src={`${baseUrl}/assets/${imageUrl}`}
              alt="Receipt"
              style={{ width: "100%" }}
            />
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(ReceiptModal);