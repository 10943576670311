import React, {
  useState,
  useEffect,
} from "react";
import { withTranslation } from "react-i18next";
import {
  useDispatch, connect
} from "react-redux";
import moment from "moment";
import {
  Button,
  Row, Col,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { fetchReportStart } from "store/reports/actions";
import CustomPagination from "components/Common/CustomPagination";

import Loader from "components/Common/Loader";
import { downloadReport } from "apis/reports";
import SendReportEmail from "./SendReportEmail";

function UsersAddModal(props) {
  const dispatch = useDispatch();
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedValues, setSelectedValues] = useState({});

  const submitReportSearch = (e, values) => {
    props.setReportColumns(values.reportType);
    setSelectedValues(values);
    dispatch(fetchReportStart(values));
  };
  const paginationLoad = (page) => {
    dispatch(fetchReportStart({
      ...selectedValues,
      page,
      limit: sizePerPage
    }));
  };
  useEffect(() => {
    if (selectedValues && selectedValues.reportType) {
      dispatch(fetchReportStart({
        ...selectedValues,
        page: 1,
        limit: sizePerPage,
      }));
    }
  }, [sizePerPage]);
  const defaultValues = {
    reportType: "deposits",
    dateFrom: moment().subtract(1, "months").format("YYYY-MM-DD"),
    dateTo: moment().add(1, "day").format("YYYY-MM-DD"),
  };

  return (
    <React.Fragment >
      <div className=" me-2 mb-2">
        <div className="position-relative">
          <AvForm
            className='p-1'
            onValidSubmit={(e, v) => {
              submitReportSearch(e, v);
            }}
            model={defaultValues}
          >
            <Row>
              <Col className="col-2">
                <label >Select Report Type</label>
                <AvField
                  type="select"
                  name="reportType"
                  errorMessage={props.t("please select report Type")}
                  validate={{ required: { value: true } }}
                >
                  <option value="">{props.t("Select Report")}</option>
                  <option value="deposits">{props.t("Deposits")}</option>
                  <option value="withdrawals">{props.t("Withdrawals")}</option>
                  <option value="commission">{props.t("Commission")}</option>
                  <option value="summary">{props.t("Summary")}</option>
                  <option value="lead-converted">{props.t("Lead Converted")}</option>
                  <option value="lead-call-status">{props.t("Lead Call Status")}</option>
                  <option value="last-login">{props.t("Last Login")}</option>
                </AvField>
              </Col>
              <Col className="col-3">
                <AvField
                  name="dateFrom"
                  label={props.t("Date From")}
                  type="date"
                  errorMessage={props.t("Select date From")}
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col className="col-3">
                <AvField
                  name="dateTo"
                  label={props.t("Date To")}
                  type="date"
                  errorMessage={props.t("Select date To")}
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col className="col-3">
                <label > Agent </label>
                <AvField
                  type="select"
                  name="agent"
                  errorMessage={props.t("Select Agent")}
                >
                  <option value="">{props.t("All")}</option>
                  {props.salesAgent.map((obj, index) => <option key={index} value={obj._id}>{`${obj.firstName} ${obj.lastName}`}</option>)}
                  <option value="deposits">{props.t("Deposits")}</option>
                </AvField>
              </Col>
              <Col className='col-1 text-center d-flex justify-content-center align-items-center mt-3'>
                {
                  props.addLoading
                    ?
                    <Loader />
                    :
                    <Button type="submit" color="primary" className="" disabled={props.loading}>
                      {props.t("Search")}
                    </Button>
                }
              </Col>
            </Row>
            <div className="d-flex justify-content-end gap-2">
              <Button
                color="primary"
                size="sm"
                disabled={!selectedValues.reportType}
                onClick={() => {
                  downloadReport(selectedValues)
                    .then((blob) => {
                      const url = window.URL.createObjectURL(blob);
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", `${selectedValues.reportType}.xlsx`);
                      document.body.appendChild(link);
                      link.click();
                    });
                }}>
                {props.t("Download Report")}
              </Button>
              <SendReportEmail reportValues={selectedValues} />
            </div>
          </AvForm>
        </div>
      </div>
      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0"
          data-pattern="priority-columns"
        >
          {props.children}
          <CustomPagination
            {...props.reportsReducer}
            setSizePerPage={setSizePerPage}
            sizePerPage={sizePerPage}
            onChange={paginationLoad}
          />
        </div>
      </div>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  loading: state.reportsReducer.loading || false,
  salesAgent: state.usersReducer.salesAgent || [],
  reportsReducer: state.reportsReducer || {},

});
export default connect(mapStateToProps, null)(withTranslation()(UsersAddModal));
