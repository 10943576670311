import React, { useEffect, useState } from "react";
import {
  useDispatch, connect
} from "react-redux";

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader
} from "reactstrap";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { getSalesAgentsStart } from "store/users/actions";

import { MetaTags } from "react-meta-tags";

import ReportForm from "./ReportForm";
import TableLoader from "components/Common/TableLoader";
import formatDate from "helpers/formatDate";

function getColumns (reportType) {
  switch (reportType) {
    case "deposits":
      return [
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "profit",
          text: "Amount",
        },
        {
          dataField: "comment",
          text: "Comment",
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)

        }
      ];
    case "commission":
      return [
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "profit",
          text: "Amount",
        },
        {
          dataField: "action",
          text: "Action",
          formatter: (val) => (val.action === 10 ? "Rebate" : "Commission")
        },
        {
          dataField: "clientDealId",
          text: "Client Deal",
        },
        {
          dataField: "clientLogin",
          text: "Client Login",
        },
        {
          dataField: "clientVolume",
          text: "Client Volume",
          formatter: (val) => (val.action === 10 ? val.clientVolumeClosed : val.clientVolume)
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)

        }
      ];
    case "summary":
      return [
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "dealId",
          text: "Deal Id",
        },
        {
          dataField: "positionId",
          text: "Position Id",
        },
        {
          dataField: "price",
          text: "Price",
        },
        {
          dataField: "volumeClosed",
          text: "Volume",
        },
        {
          dataField: "profit",
          text: "Amount",
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)

        }
      ];
    case "lead-converted":
      return [
        {
          dataField: "name",
          text: "Name",
          formatter: (val) => (`${val.firstName} ${val.lastName}`)
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) => (val.agent ? `${val.agent.firstName} ${val.agent.lastName}` : "")
        },
        {
          dataField: "convertTime",
          text: "Convert TIme",
          formatter: (val) => formatDate(val.fx.demoConvertTime)
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        },
      ];
    case "lead-call-status":
      return [
        {
          dataField: "name",
          text: "Name",
          formatter: (val) => (`${val.firstName} ${val.lastName}`)
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) => (val.agent ? `${val.agent.firstName} ${val.agent.lastName}` : "")
        },
        {
          dataField: "callStatus",
          text: "Call Status",
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        },
      ];
    case "last-login":
      return [
        {
          dataField: "name",
          text: "Name",
          formatter: (val) => (`${val.firstName} ${val.lastName}`)
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) => (val.agent ? `${val.agent.firstName} ${val.agent.lastName}` : "-")
        },
        {
          dataField: "lastLogin",
          text: "Last Login",
          formatter: (val) => formatDate(val.lastLogin)
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        },
      ];
    default: 
      return [
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "profit",
          text: "Amount",
        },
        {
          dataField: "comment",
          text: "Comment",
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        }
      ];
  }
}

function ShowStats ({ data }) {
  if (!data) return <></>;
  try {
    let rawHtml = "";
    Object.keys(data).forEach(key => {
      rawHtml += `<div><strong style="margin-left:10px; margin-right:5px; text-transform: capitalize">${key} :</strong>${data[key]}</div>`;
    });
    return <div dangerouslySetInnerHTML={{ __html: rawHtml }}></div>;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return "";
  }
}

function ReportsList(props) {
  const dispatch = useDispatch();

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    dispatch(getSalesAgentsStart({
      page:1,
      limit:1000
    }));
  }, []);
  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Reports
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>Reports</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle>
                    Reports
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ReportForm setReportColumns={(type)=>{setColumns(getColumns(type))}} >                    
                    <Table
                      id="tech-companies-1"
                      className="table  table-hover "
                    >
                      <Thead className="text-center table-light" >
                        <Tr>
                          {columns.map((column, index) =>
                            <Th data-priority={index} key={index}>{column.text}</Th>
                          )}
                        </Tr>
                      </Thead>
                      <Tbody className="text-center" style={{ fontSize: "13px" }}>
                        {props.loading && <TableLoader colSpan={4} />}
                        {!props.loading && props.docs.length === 0 && 
                          <>
                            <Tr>
                              <Td colSpan={"100%"} className="fw-bolder text-center" >
                                <h3 className="fw-bolder text-center">No records</h3>
                              </Td>
                            </Tr>
                          </>
                        }
                        {!props.loading && props.docs.map((row, rowIndex) =>
                          <Tr key={rowIndex}>
                            {columns.map((column, index) =>
                              <Td key={`${rowIndex}-${index}`}>
                                { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                              </Td>
                            )}
                          </Tr>
                        )}
                        <Tr>
                          <Td colSpan={columns.length}>
                            <ShowStats data={props.reportsReducer.stats} />
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </ReportForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.reportsReducer.loading || false,
  docs: state.reportsReducer.docs || [],
  reportsReducer: state.reportsReducer || {},


});
export default connect(mapStateToProps, null)(ReportsList);
