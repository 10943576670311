import React from "react";
import { connect } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// i18n
import { withTranslation } from "react-i18next";
import Stages from "./Stages";
import Activities from "./Activities";
import { useParams } from "react-router-dom";

function Logs() {
  const { clientId } = useParams();

  return (
    <>
      <Stages clientId={clientId}/>
      <Activities clientId={clientId}/>
    </>
  );
}

const mapStateToProps = () => ({

});

export default connect(mapStateToProps, null)(withTranslation()(Logs));
