
import React, { useRef } from "react";
import {
  AvField
} from "availity-reactstrap-validation";
import Select from "react-select";

function AvFieldSelect(props) {
  const ref1 = useRef();
  const [state, setState] = React.useState(null);
  const onFieldChange = (e) => {
    if (props.ismulti){
      setState(e.map(obj => obj.value));
      if (props.onChange) {
        props.onChange(e.map(obj => obj.value));
      }
    } else {
      setState(e.value);
      if (props.onChange) {
        props.onChange(e.value);
      }
    }
  };
  const { label, ...params } = props;
  let options = props.options || [];

  // React.useEffect(()=>{
  //   setState(params.value);
  // }, [props]);
  return (<React.Fragment>
    { label ? <label>{label}</label> : null }
    <Select
      ref={ref1}
      placeholder={props.placeholder}
      defaultValue={options.find(obj => obj.value === props.value) || props.placeholder}
      options={options}
      onChange={onFieldChange}
      isMulti={props.ismulti}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          marginBottom: -10,
        }),
      }}
    />
    <AvField
      {...params}
      type="select"
      value={state === null ? props.value : state}
      style={{
        opacity: 0,
        height: 0,
        margin: -10,
        "pointerEvents": "none",
      }}
    >
      {options.map((obj, index) => {
        return (<option key={index} value={obj.value}>{obj.label}</option>);
      })}
    </AvField>
  </React.Fragment>);
}

export default AvFieldSelect;
