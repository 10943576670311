import React, { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Modal, ModalHeader, ModalBody, Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Loader from "components/Common/Loader";
import { useEffect } from "react";
import { updateLeverage } from "store/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";

const ChangeLeverage = ({ show, toggle, selectedAccount }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tradingAccounts = useSelector((state) => state.tradingAccountReducer.accounts.docs);
  const { submitting, error } = useSelector((state) => state.tradingAccountReducer);


  useEffect(() => {
    if (submitting === false && error === null && show === true) {
      toggle();
    }
  }, [submitting]);

  return (
    <>
      <button 
        type="button" 
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggle}
      >
        {t("Change Leverage")}
      </button>
      <Modal centered isOpen={show} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {t("Change Leverage")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={(e, v) => {
              dispatch(updateLeverage({
                _id: v.account,
                body: {
                  leverage: v.leverage,
                },
              }));
            }}
          >
            <AvFieldSelect
              name="account"
              label={t("Select Account")}
              value={selectedAccount?._id ?? ""}
              options={tradingAccounts
                ?.filter((account) => account.type === "LIVE")
                ?.map((account) => ({
                  value: account._id,
                  label: `${account.login} (${account.MarginLeverage})`,
                }))}
            />
            <AvField type="select" name="leverage"
              value="100"
              label={t("Select Leverage")}
              className="form-select mt-1">
              <option value={"100"}>1:100</option>
              <option value={"400"}>1:400</option>
              <option value={"1000"}>1:1000</option>
            </AvField>
            <div className="text-center mt-3 mb-1">
              {
                submitting
                  ? <Loader />
                  : <Button type="submit" className="border-0 color-bg-btn shadow">{t("Change")}</Button>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChangeLeverage;